import React, { Fragment } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import getPlaceholderModule from "quill-placeholder-module";
import "./QuillEditor.css";

var Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block, true);

Quill.register("modules/placeholder", getPlaceholderModule(Quill));

const modules = {
  // toolbar: [
  //   [{ header: [1, 2, false] }],
  //   ["bold", "italic", "underline", "strike", "blockquote"],
  //   [
  //     { list: "ordered" },
  //     { list: "bullet" },
  //     { indent: "-1" },
  //     { indent: "+1" },
  //   ],
  //   ["link", "image"],
  //   ["clean"],
  // ],
  // clipboard: {
  //   matchVisual: false,
  // },

  toolbar: "#toolbar",

  placeholder: {
    delimiters: ["{{", "}}"],
    placeholders: [
      {
        id: "scSalutationForm",
        label: "Grußformel",
        displayName: "Grußformel",
      },
      {
        id: "scSalutation",
        label: "Anrede",
        displayName: "Anrede",
      },
      { id: "scTitle", label: "Titel", displayName: "Titel" },
      { id: "scFirstname", label: "Vorname", displayName: "Vorname" },
      { id: "scLastname", label: "Nachname", displayName: "Nachname" },
      { id: "scOrgname", label: "Organisation", displayName: "Organisation" },
      { id: "spName", label: "Projekt", displayName: "Projekt" },
      {
        id: "licenceKundenname",
        label: "Kundenname",
        displayName: "Kundenname",
      },
    ],
  },
};

const CustomToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="sub" />
      <button className="ql-script" value="super" />
    </span>
    <span className="ql-formats">
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-direction" value="rtl" />
    </span>
    <span className="ql-formats">
      <select className="ql-header" defaultValue={"normal"}>
        <option value="1" />
        <option value="2" />
        <option value="3" />
        <option value="4" />
        <option value="5" />
        <option value="6" />
        <option value="normal" />
      </select>
    </span>

    <span className="ql-formats">
      <select className="ql-font" defaultValue={"sans-serif"}>
        <option value="sans-serif" />
        <option value="serif" />
        <option value="monospace" />
      </select>
    </span>

    <span className="ql-formats">
      <select className="ql-color" defaultValue={"#ffffff"}>
        <option value="#000000" />
        <option value="#e60000" />
        <option value="#ff9900" />
        <option value="#ffff00" />
        <option value="#008a00" />
        <option value="#0066cc" />
        <option value="#9933ff" />
        <option value="#ffffff" />
        <option value="#facccc" />
        <option value="#ffebcc" />
        <option value="#ffffcc" />
        <option value="#cce8cc" />
        <option value="#cce0f5" />
        <option value="#ebd6ff" />
        <option value="#bbbbbb" />
        <option value="#f06666" />
        <option value="#ffc266" />
        <option value="#ffff66" />
        <option value="#66b966" />
        <option value="#66a3e0" />
        <option value="#c285ff" />
        <option value="#888888" />
        <option value="#a10000" />
        <option value="#b26b00" />
        <option value="#b2b200" />
        <option value="#006100" />
        <option value="#0047b2" />
        <option value="#6b24b2" />
        <option value="#444444" />
        <option value="#5c0000" />
        <option value="#663d00" />
        <option value="#666600" />
        <option value="#003700" />
        <option value="#002966" />
        <option value="#3d1466" />
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
    </span>
    <span className="ql-formats">
      <select className="ql-placeholder">
        {modules.placeholder.placeholders.map((p) => (
          <option value={p.id} key={p.id}>
            {p.displayName}
          </option>
        ))}
      </select>
    </span>
  </div>
);

export const QuillEditor = (props) => {
  return (
    <Fragment>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        value={props.emailTemplate}
        defaultValue={props.emailTemplate}
        onChange={(value) => {
          props.setEmailTemplate(value);
        }}
        style={{ height: "500px" }}
        modules={modules}
        readOnly={false}
      />
    </Fragment>
  );
};
